import React from 'react'
import PropTypes from 'prop-types'

import devWeek from '../images/devWeek.resized.jpg'
import devWeekWin from '../images/devWeekWin.resized.jpg'
import baker from '../images/baker-beach.resized.jpg'
import hrGroup from '../images/hr-group.resized.jpg'

/* eslint-disable */

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      />
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">About Me</h2>
          <span className="image main" />
          <img src={baker} width="100%" />
          <hr width="100%" />
          <p>
            In late 2018, I decided to invest five months of my time into Hack
            Reactor, a software engineering immersive program based out of San
            Francisco, where I learned from veterans in the field, and rapidly
            developed my skills in full-stack development. <br /> <br /> Here, I
            also learned a myriad of new habits and workflows. On dozens of
            occasions, I had the opportunity to work in teams of various sizes,
            where I practiced scrum methodology, which I've continued to utilize
            in my personal projects after the program.
            <br /> <br />
            Today, I am leading and contributing to a handful of passion
            projects, where I have the opportunity to ensure quality code and
            workflow.
            <br /> <br />
            Beyond this, I have been fortunate to have built out a solid and
            reliable network of like-minded individuals. I continue to do so
            each week through tech-related events and Meetups.
          </p>
          <img src={hrGroup} width="100%" />
          {close}
        </article>

        <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Projects</h2>
          <span className="image main" />
          <b>
            DeveloperWeek 2019 Hackathon - <i>2nd Place Overall</i>
          </b>
          <p>
            In February of this year, I participated in the DeveloperWeek SF
            Hackathon, where I had the opportunity to work with a diverse and
            passionate team. Together, we built a full stack application
            utilizing DocuSign’s eSignature verification and Clarifai’s image
            recognition.{' '}
            <b>Our team, Clarisign, won 2nd place overall, among 150 teams</b>.{' '}
            <br /> <br />
            Clarisign utilizes the DocuSign API for eSignature verification, and
            the Clarifai API for image/facial recognition. Designed with the
            business proposition of adding an additional verification step to
            ensure secure package delivery, requiring signature and facial
            recognition of the recipient for a complete delivery.
            <br /> <br />
            <a
              href="https://blog.clarifai.com/computer-vision-developerweek-sf-hackathon?utm_content=86213426&utm_medium=social&utm_source=linkedin&hss_channel=lcp-10064814"
              target="_blank"
              rel="noopener noreferrer"
            >
              See what Clarifai, the creators of the image recognition
              technology we used, had to say in their blog article covering the
              hackathon.
            </a>
          </p>
          <img src={devWeek} width="100%" />
          <img src={devWeekWin} width="100%" />
          <hr width="100%" />
          <b>A few of my other recent projects</b> <br />
          <a
            href="https://github.com/QueryMasters/collin-reviews-service"
            target="_blank"
            rel="noopener noreferrer"
          >
            QueryMasters
          </a>
          {
            ' - System design & backend development of an e-commerce reviews component'
          }
          <ul>
            <li>
              Scaled a reviews component in order to meet and handle web-scale
              traffic with a throughput of 1,000 requests per second and an
              error rate under 1%, querying a PostgreSQL database of 50 million
              records
            </li>
            <li>
              Deployed to an AWS Virtual Private Cloud, allowing for use of an
              Auto Scaling Group and Elastic Load Balancer with EC2, to
              distribute traffic across multiple Regions and Availability Zones
            </li>
          </ul>
          <a
            href="https://github.com/critiqueEats/header"
            target="_blank"
            rel="noopener noreferrer"
          >
            Critique Eats
          </a>
          {' - Dynamic header component for an e-commerce product page'}
          <ul>
            <li>
              Using the Scrum methodology, developed and deployed a fullstack
              app to AWS, using React, Express, and MongoDB
            </li>
            <li>
              Containerized the app using Docker in order to implement rapid and
              flexible deployment
            </li>
            <li>
              Styled app using React CSS Modules to address CSS specificity,
              avoiding conflict between modules
            </li>
            <li>
              Integrated Trello with Github to encourage transparency and
              peer-review with a team of four
            </li>
          </ul>
          <a
            href="https://github.com/Collin-St/Last.fm-Dashboard"
            target="_blank"
            rel="noopener noreferrer"
          >
            Last.fm Dashboard
          </a>
          {' - Simple Dashboard MVP using the last.fm API'}
          <ul>
            <li>
              Used the Last.fm API to create a search feature and dashboard to
              display summarized data returned from the platform’s database
            </li>
            <li>
              Organized data by date and various rankings in order to best
              represent user information
            </li>
          </ul>
          <hr width="100%" />
          <b>
            CUNY-IBM Watson Case Competition - <i>Semi-Finalist</i>
          </b>
          <p>
            In early 2018, I took part in a cross-disciplined, CUNY-wide annual
            competition known as the <b>CUNY-IBM Watson Case Competition</b>.
            The CUNY-IBM Watson Case Competition is a collaboration between
            Baruch College’s Marxe School of Public and International Affairs,
            IBM Corporate Citizenship, the City University of New York, and the
            New York City Government. <br /> <br /> My team, <b>DataPass</b>,
            chose to focus on improving the NYC Electronic Medical Records
            system, by designing a decentralized store where EMR could be both
            stored and analyzed by IBM Watson. <br /> <br /> Here is a brief
            Case Analysis my team put together which advanced us into the
            semi-finals:
          </p>
          <iframe
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/Up4ERF5JpXY"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
          <p>
            <br />
            And here is the Executive Summary I wrote to be submitted to a panel
            of leaders in local government and healthcare:
          </p>
          <iframe
            src="https://drive.google.com/file/d/154D0YT-5-_EVBZGm7SHYBA6yY7tbqSFa/preview"
            width="100%"
            height="900px"
          />
          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Resume</h2>
          <span className="image main" />
          <a href="https://drive.google.com/uc?export=download&id=1qMbZP9I3i9lUyCRask_KqHn9fFtfb2Mz">
            Download my resume in PDF format
          </a>
          <br /> <br />
          <div
            className="resp-container"
            position="relative"
            overflow="hidden"
            padding-top="56.25%"
          >
            <iframe
              className="resp-iframe"
              src="https://drive.google.com/file/d/1qMbZP9I3i9lUyCRask_KqHn9fFtfb2Mz/preview"
              position="absolute"
              top="0"
              left="0"
              width="100%"
              height="965px"
              border="0"
            />
          </div>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          <ul className="icons">
            <li>
              <a
                href="https://github.com/Collin-St/"
                target="_blank"
                rel="noopener noreferrer"
                className="icon fa-github"
              >
                <span className="label">GitHub</span>
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/in/collin-street/"
                target="_blank"
                rel="noopener noreferrer"
                className="icon fa-linkedin"
              >
                <span className="label">linkedin</span>
              </a>
            </li>
            <li>
              <a className="icon fa-phone">
                <span className="label" />
              </a>{' '}
              (704)-942-5621
            </li>
            <li>
              <a className="icon fa-envelope">
                <span className="label" />
              </a>{' '}
              collin.street.dr@gmail.com
            </li>
          </ul>
          {/* <form method="post" action="#">
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                name="name"
                id="name"
                placeholder="Your name"
              />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input
                type="text"
                name="email"
                id="email"
                placeholder="Your email"
              />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4" />
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form> */}

          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
